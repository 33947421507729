import React from "react";
import "./section-ponentes.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import pon1 from "../../assets/img/section-ponentes/stanleyVelandopg.png"
import pon2 from "../../assets/img/section-ponentes/joseVargas.png"
import pon3 from "../../assets/img/section-ponentes/erickIriarte.png"
import pon4 from "../../assets/img/section-ponentes/albertoMendoza.png"
import pon5 from "../../assets/img/section-ponentes/omarPalomino.png"


export function SectionPonentes() {
    return (
        <div className="gray">
            <div className="container-sm">
                <div className="container-ponentes pt-5 ">
                    <div className="row aling-items-center justify-content-center m-3">
                        <h6 className="pre-title-ponentes">PONENTES</h6>
                        <h2 className="title-ponentes">Conecta con expertos e impulsa tu carrera</h2>
                    </div>
                   
                        <Swiper
                            modules={[Autoplay,Navigation, Pagination]}
                            slidesPerView={1}
                            spaceBetween={30}
                            navigation                          
                            loop={true}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: false,
                              }}
                            centeredSlides={true}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            className="mySwiper">
                            <SwiperSlide>
                           
                                <div className="d-lg-flex w-100 justify-content-center">                
                                    <div className="order-lg-2 info-ponente">
                                        <div className="row aling-items-center justify-content-center m-3">
                                            <h2 className="title-ponentes">La SBS y el marco de trabajo NIST-CSF, Cyber Security Framework</h2>
                                        </div>
                                        <div class="container-textcontacto mb-3"><div class="rct1"></div><div class="text-contacto"><label class="description">Stanley Velando</label></div></div>
                                        <label class="letras-ponente" htmlFor="">Director de KUNAK, una firma peruana líder en consultoría en seguridad de información y ciberseguridad, será uno de los destacados ponentes. Ingeniero de Sistemas y con certificaciones en PMP, ISO27001 LI y LA e ISO31000 RM, así como CISA y CRISC de ISACA, aportará su experiencia y conocimientos excepcionales.</label>
                                    </div>
                                    <img className="order-lg-1 img-ponente" src={pon1} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="d-lg-flex w-100 justify-content-center ">
                                <div className="order-lg-2 info-ponente">
                                        <div className="row aling-items-center justify-content-center m-3">
                                            <h2 className="title-ponentes">La IA Generativa  y su Rol Transformador en la Ciberseguridad</h2>
                                        </div>
                                        <div class="container-textcontacto mb-3"><div class="rct1"></div><div class="text-contacto"><label class="description">José Carlos Vargas Medina</label></div></div>
                                        <label class="letras-ponente" htmlFor="">Presidente del Capítulo ISACA Lima 146 y Gerente de Operaciones de Seguridad TI en el Centro de Servicios Compartidos Pacífico Seguros y Prima AFP, será parte de nuestro evento. Certificado en CISM, CISA, y CDPSE, aportará su experiencia única en ciberseguridad.</label>
                                    </div>
                                    <img className="order-lg-1 img-ponente" src={pon2} alt="" />                             
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="d-lg-flex w-100 justify-content-center ">
                                <div className="order-lg-2 info-ponente">
                                        <div className="row aling-items-center justify-content-center m-3">
                                            <h2 className="title-ponentes">Anoniminzación de Datos Personales</h2>
                                        </div>
                                        <div class="container-textcontacto mb-3"><div class="rct1"></div><div class="text-contacto"><label class="description"> Erick Iriarte</label></div></div>
                                        
                                        <label class="letras-ponente" htmlFor="">Socio Principal de Iriarte & Asociados. Abogado. Magister en Ciencia Política y Gobierno con mención en Políticas Públicas y Gestión Pública (PUCP). Doctorando en Gestión Estratégica de Negocios por PUCP/CENTRUM. CEO de eBIZ. Fue Primer General Manager LACTLD, asociación de ccTLDs de América Latina.</label>
                                    </div>
                                    <img className="order-lg-1 img-ponente" src={pon3} alt="" />                             
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="d-lg-flex w-100 justify-content-center ">
                                <div className="order-lg-2 info-ponente">
                                        <div className="row aling-items-center justify-content-center m-3">
                                            <h2 className="title-ponentes">Ingeniería Social: Estrategias para Prevenir la Fuga de Información en el Mundo Digital</h2>
                                        </div>
                                        <div class="container-textcontacto mb-3"><div class="rct1"></div><div class="text-contacto"><label class="description"> Alberto Mendoza de los Santos</label></div></div>
                                        
                                        <label class="letras-ponente" htmlFor="">Especialista en Gobierno de Tecnologías de la Información y Gestor de Proyectos de Tecnología de la Información, será una voz clave en nuestro evento. Poseedor de la certificación CISA de ISACA y PECB Certified ISO/IEC 27001 Senior Lead Implementer, Alberto aporta su experiencia como Gerente General de BUSINESS SOLUTION ENTERPRISE S.A.C., una destacada empresa de asesoramiento empresarial</label>
                                    </div>
                                    <img className="order-lg-1 img-ponente" src={pon4} alt="" />                             
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="d-lg-flex w-100 justify-content-center ">
                                <div className="order-lg-2 info-ponente">
                                        <div className="row aling-items-center justify-content-center m-3">
                                            <h2 className="title-ponentes">Ciberinteligencia - Técnicas OSINT para procesos de PENTESTING</h2>
                                        </div>
                                        <div class="container-textcontacto mb-3"><div class="rct1"></div><div class="text-contacto"><label class="description">Omar Palomino</label></div></div>
                                        
                                        <label class="letras-ponente" htmlFor="">Con más de 15 años de experiencia profesional, Omar ha liderado proyectos de Ethical Hacking, Pentesting y Red Team, consolidándose como experto en ciberseguridad. Su trayectoria incluye ser Instructor para la firma internacional Ec-Council y para Inictel Perú, demostrando su compromiso con la formación y el impulso del conocimiento en la industria.</label>
                                    </div>
                                    <img className="order-lg-1 img-ponente" src={pon5} alt="" />                             
                                </div>
                            </SwiperSlide>
                        </Swiper>
                   
                </div>
            </div>
        </div>
    );
}




