import React from "react";
import "./contador_header.css";
import logoAzul from '../../assets/img/contador/Logo_Azul.png';

export function ContadorHeader() {
  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  
  //  const contadorStyles = {
  //     width: isSticky ? "" : "100%", 
  //     left: isSticky? "50%": "0%",
  //     top: isSticky? "" : "100px",
  //     borderRadius:  isSticky? "":"0",
  //     height : isSticky?"":"80px"
  //   };
  //   const imgStyles = {
  //     left: isSticky ?"" :"40%",
  //     position: isSticky ?"":'relative'

  //   };

  React.useEffect(() => {
    // Fecha objetivo (18 de noviembre a las 8:30 a.m.)
    const targetDate = new Date("2024-01-27T08:30:00").getTime();

    const updateCountdown = () => {
      const currentTime = new Date().getTime();
      const timeDifference = targetDate - currentTime;

      if (timeDifference <= 0) {
        // Si la fecha objetivo ha pasado, detén el contador
        clearInterval(timerID);
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    };

    // Actualiza el contador cada segundo
    const timerID = setInterval(updateCountdown, 1000);

    return () => {
      // Detén el temporizador cuando el componente se desmonte
      clearInterval(timerID);
    };
  }, []);

  return (
    <div className="row">
      {/* <Sticky enabled={true} top={0} bottomBoundary={1200} onStateChange={handleStickyStateChange}> */}
      <div className="container-contador" >
        <div className="container-img-logo" >
          <img className="img-logo" src={logoAzul} alt="" />
        </div>
        <div className="contador-position" >
        <div className="contador"  >
          <div className="tiempo">
            <div className="sec-time">
              <span className="time">{days} </span>
              <span className="time-t">Días</span>
            </div>

            <div className="separator" >:</div>

            <div className="sec-time">
              <span className="time">{hours} </span>
              <span className="time-t">Horas</span>
            </div>

            <div className="separator" >:</div>

            <div className="sec-time">
              <span className="time">{minutes} </span>
              <span className="time-t">Minutos</span>
            </div>

            <div className="separator" >:</div>

            <div className="sec-time">
              <span className="time">{seconds} </span>
              <span className="time-t">Segundos</span>
            </div>
          </div>
        </div>
        </div>
      </div>

    </div>


  );
}
