import './App.css';
import { ContadorHeader } from './components/contador_header/contador_header';
import 'bootstrap/dist/css/bootstrap.css';
import { SectionForm } from './components/section-formulario/section-formulario';
import { SectionVentajas } from './components/section-ventajas/section-ventajas';
import Sticky from 'react-stickynode';
import { SectionPonentes } from './components/section-ponentes/section-ponentes';
import { SectionCostos } from './components/section-costos/section-costos';
import { SectionInscripcion } from './components/section-inscripcion/section-inscripcion';
// import { SectionPatrocionadores } from './components/section-patrocinadores/section-patrocinadores';
import { SectionFooter } from './components/section-footer/section-footer';

function App() {
  return (
   <div className="container-fluid">
    <div className="App">
      <div className="ContadorHeader">
      <Sticky enabled={true} top={0} bottomBoundary={Infinity} >
        <ContadorHeader ></ContadorHeader>
        </Sticky>
      </div>
      <div className="SectionForm" >
        <SectionForm />
      </div>
      <SectionVentajas/>
      <SectionPonentes/>
      <SectionCostos/>
      <SectionInscripcion/>
      {/* <SectionPatrocionadores/> */}
      <SectionFooter/>
      </div>
    </div> 
  );
}

export default App;
