import React from "react";
import "./section-costos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { MetodosPago } from "./metodos-pago/metodos-pago";

export function SectionCostos() {
    const costosDatos = [{
        opcion: 'BÁSICO',
        categoria: "Estudiante",
        precio: 'S/. 40.00',
        items: ['Ponencias Magistrales'],
        estilos: {
            
            rectangulo2: {
                backgroundColor: '#00B68B',
                borderBottom: '20px solid #106C5F',
            },
            card: {
                border: '7px solid #00B68B ',
                borderRadius: '8px'
            }
        },
    },
    {
        opcion: 'COMPLETO',
        categoria: "Estudiante",
        precio: 'S/. 60.00',
        items: ['Ponencias Magistrales', 'Certificado', 'Material de apoyo'],
        estilos: {
           
            rectangulo2: {
                backgroundColor: '#00B871',
                borderBottom: '20px solid #057046',
            },
            card: {
                border: '7px solid #00B871 ',
                borderRadius: '8px'
            }
        },
    }, {
        opcion: 'BÁSICO',
        categoria: "Profesional",
        precio: 'S/. 60.00',
        items: ['Ponencias Magistrales'],
        estilos: {
           
            rectangulo2: {
                backgroundColor: '#00BA5A',
                borderBottom: '20px solid #036F37',
            },
            card: {
                border: '7px solid #00BA5A ',
                borderRadius: '8px'
            }
        },
    },
    {
        opcion: 'COMPLETO',
        categoria: "Profesional",
        precio: 'S/. 80.00',
        items: ['Ponencias Magistrales', 'Certificado', 'Material de apoyo'],
        estilos: {
           
            rectangulo2: {
                backgroundColor: '#00BC48',
                borderBottom: '20px solid #139551',
            },
            card: {
                border: '7px solid #00BC48 ',
                borderRadius: '8px'
            }
        },
    },
    ];
    return (
        <div className=" gray">
            <div className="container container-costos py-5 mt-3 pb-0">
                <div className="row aling-items-center justify-content-center">
                    <h6 className="pre-title-ventajas">COSTOS</h6>
                    <h2 className="title-ventajas">Tarifas y Detalles</h2>
                </div>
                <div className="cards mt-3">
                    {costosDatos.map((data, index) => (
                        <div className="container-card-costos">
                            <div className="card-costos"  style={data.estilos.card}>
                             
                                <div className="rectangulo-2" style={data.estilos.rectangulo2}>
                                    <div className="rectangulo-1" style={data.estilos.rectangulo1}>
                                    <label className="option">{data.opcion}</label>
                                    <h1 className="asistente">{data.categoria}</h1>
                                    </div>
                                    <label className="precio">{data.precio}</label>
                                </div>
                                <div className="rectangulo-3">
                                    {data.items.map((item, itemIndex) => (
                                        <div className="item" key={itemIndex}>
                                            <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{ color: "#052954" }} />
                                            <label className="descripcion-item">{item}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="metodos-container">
                <MetodosPago></MetodosPago>
            </div>
        </div>
    );
}
