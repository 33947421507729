import React from "react";
import "./metodos-pago.css";
import yape from '../../../assets/img/section-costos/yape_plin.png';
import bcp from '../../../assets/img/section-costos/logo_bcp.png';
import interbank from '../../../assets/img/section-costos/interbank.png';


export function MetodosPago() {
   
    return (
        <div className="row">
            <div className="container-metodos">
                <div className="container-title">
                <label className="title">Métodos de Pago</label>
                </div>
                <div className="metodos">
                    <div className="metodo-pago">
                        <img className="img-entidades"  src={bcp}  alt="" />
                        <div className="nro">
                            <label className="desc">N° de cuenta(Veronica Lopez Zavaleta)</label>
                            <label className="nCuenta">57070721304014</label>
                            <label className="nCuenta">CCI:00257017072130401409</label>

                        </div>
                        
                    </div>
                    <div className="metodo-pago">
                                          
                                          <img className="img-entidades"  src={yape}  alt="" />
                                     
                                      <div className="nro">
                                      <label className="desc">Veronica Lopez Zavaleta</label>
                                          <label className="nCuenta">+51 921351292</label>
                                      </div>
                                  </div>
                    <div className="metodo-pago">
                        <img className="img-entidades"  src={interbank}  alt="" />
                        <div className="nro">
                            <label className="desc">N° de cuenta(Kenner Rojas Ahumada)</label>
                            <label className="nCuenta">8983331787819</label>
                            <label className="nCuenta">CCI:00389801333178781946</label>                 
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    );
}
