import React, { useState } from "react";
import "./section-formulario.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock, faEnvelope, faMapMarkerAlt, faPaperPlane, faPhoneVolume, faUser } from "@fortawesome/free-solid-svg-icons";
import emailjs from 'emailjs-com'
import Snackbar from '@material-ui/core/Snackbar';
export function SectionForm() {
    const [form, setForm] = useState({ name: '', email: '', phone: '' });
    const [errors, setErrors] = useState({ name: '', email: '', phone: '' });
    const [open, setOpen] = useState(false);
    const handleChange = (e) => {
      setForm({ ...form, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: '' });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
         // Comprueba si todos los campos están llenos
            let errors = {};
            if (!form.name) errors.name = 'El nombre es obligatorio';
            if (!form.email) errors.email = 'El correo electrónico es obligatorio';
            if (!form.phone) errors.phone = 'El teléfono es obligatorio';
            
            // Si hay errores, actualiza el estado y detiene la ejecución de la función
            if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
            }
 
        emailjs.send('service_9m02m6l', 'template_livhd05', form, 'S-1meqe34HDmJVcea')
          .then((result) => {
            setForm({ name: '', email: '', phone: '' }); 
            setOpen(true); 
          }, (error) => {
            console.log(error.text);
          });
      };
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);  // cierra el Snackbar
      };


    return (
        <div className="row">
            <div className="container-form">
                    <div className="row h-100 align-items-center px-lg-2">
                        <div className="col-lg-7 col-xl-6 col-12">
                            <div className="row justify-content-center aling-items-center">
                                <h1 className="title"> IX FULL DAY ISACA</h1>
                            </div>
                            <div className="row justify-content-center aling-items-center my-2">
                                <div className="datos-generales">
                                    <div className="datos">
                                        <div className="dato">
                                            <div className="rtgl" style={{ background: '#00A3DD' }}></div>
                                            <FontAwesomeIcon icon={faCalendarAlt} style={{color: "#ffffff"}} />                                      
                                            <label className="subtitle">Sábado 27 de Enero</label>
                                        </div>
                                        <div className="dato">
                                            <div className="rtgl" style={{ background: '#00B68B' }}></div>
                                            <FontAwesomeIcon icon={faClock} style={{color: "#ffffff",}} />                                        
                                            <label className="subtitle">8:30 am</label>
                                        </div>
                                        <div className="dato">
                                            <div className="rtgl" style={{ background: '#00BD41' }}></div>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} style={{color: "#ffffff"}} />                                      
                                            <label className="text-location">Auditorio Copérnico(Segunda Puerta-UNT)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center aling-items-center my-3">
                                <h1 className="subtitle-inscribete"> Inscribete ya!</h1>
                            </div>
                            <div className="row justify-content-center aling-items-center mt-3">
                                <form action="" method="post" onSubmit={handleSubmit} >
                                    <div className="sec-formulario">
                                        <div className="input-container">
                                            <FontAwesomeIcon className="icon" icon={faUser} style={{color: "#999",}} />
                                            <input className="form-control" type="text"
                                            name="name" value={form.name} onChange={handleChange} placeholder="Nombres" />
                                        </div>
                                        {errors.name && <div className="error" style={{marginTop:"0px",marginBottom:"0px"}}>{errors.name}</div>}

                                        <div className="input-container">
                                        <FontAwesomeIcon className="icon"  icon={faEnvelope} style={{color: "#999",}} />                                            
                                        <input className="form-control" type="email"
                                        name="email" value={form.email} onChange={handleChange} 
                                         placeholder="Correo"  />
                                        </div>
                                        {errors.email && <div className="error" >{errors.email}</div>}
                                        <div className="input-container">
                                        <FontAwesomeIcon className="icon"  icon={faPhoneVolume} style={{color: "#999",}} />                                           
                                         <input className="form-control" type="tel" name="phone" value={form.phone} onChange={handleChange} placeholder="+51 Celular"/>
                                        </div>
                                        {errors.phone && <div className="error">{errors.phone}</div>}   
                                    </div>
                                    <div className="btn-send">
                                        <button type="submit" className="btn-envio"><FontAwesomeIcon icon={faPaperPlane} style={{color: "#052a4b",}} /> <span> Enviar</span></button>
                                    </div>
                                </form>
                                <label  className="decription" style={{color: '#FFF'}}>Validad tu pago llenando el siguiente formulario: <a href="https://forms.gle/h6cAAVkyLoASqLTTA" target="_blanck">Click Aquí!</a></label>
                            </div>

                        </div>
                    </div>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message="Los datos se enviaron correctamente"
            />
        </div>
    );
  }