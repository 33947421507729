import React, { useEffect } from "react";
 import "./section-ventajas.css";
 import ventaja1 from '../../assets/img/section-ventajas/ventaja1.png';
 import ventaja2 from '../../assets/img/section-ventajas/ventaja2.png';
 import ventaja3 from '../../assets/img/section-ventajas/ventaja3.png';
 import 'animate.css';



export function SectionVentajas() {
    useEffect(() => {
        // Agregar la clase de animación de entrada a los elementos después de que se monte el componente.
        const advantages = document.querySelectorAll('.container-advantage');
        advantages.forEach((advantage, index) => {
          advantage.classList.add(`animate__animated`, `animate__fadeInUp`, `animate__delay-${index}s`);
        });
      }, []);


    return (
        <div className="row gray">
            <div className="container-ventajas py-5 my-3">
                <div className="row aling-items-center justify-content-center">
                <h6 className="pre-title-ventajas">VENTAJAS DE PARTICIPAR</h6>
                    <h2 className="title-ventajas">Tu aprendizaje no tiene límites</h2>
                </div>
                <div className="container">
                <div className="advantages">
                    <div className="container-advantage">
                        <div className="container-img">
                            <img style={{height :'100px', }} src={ventaja1}  alt="" />
                        </div>
                        <div className="subtitle-adventage"> Inspiración </div>
                        <label className="description" htmlFor=""> Escuchar a expertos y líderes en el campo puede ser inspirador y motivador. Puede ayudarte a establecer metas más altas y encontrar nuevas direcciones en tu carrera.</label>
                    </div>
                    <div className="container-advantage">
                    <div className="container-img">
                            <img style={{height :'80px', }} src={ventaja2}  alt="" />
                        </div>
                        <div className="subtitle-adventage">Experiencia de expertos</div>
                        <label className="description" htmlFor="">Los ponentes suelen ser expertos en sus respectivos campos y pueden proporcionar información valiosa basada en su experiencia práctica. Puedes aprender de sus éxitos y desafíos.</label>
                    </div>
                    <div className="container-advantage">
                        <div className="container-img" >
                            <img style={{height :'80px', }} src={ventaja3}  alt="" />
                        </div>
                        <div className="subtitle-adventage">Actualización de conocimientos</div>
                        <label className="description" htmlFor=""> Obtendrás información actualizada sobre las últimas tendencias, tecnologías y mejores prácticas en TI y seguridad de la información. Esto te ayudará a estar al día con los avances en el campo.</label>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
  }