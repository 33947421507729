import React from "react";
import "./section-inscripcion.css";
import imgPaso1 from "../../assets/img/section-inscription/paso1.png"
import imgPaso2 from "../../assets/img/section-inscription/paso2.png"
import imgPaso3 from "../../assets/img/section-inscription/paso3.png"
import imgPaso4 from "../../assets/img/section-inscription/paso4.png"
import imgPaso5 from "../../assets/img/section-inscription/paso5.png"


export function SectionInscripcion() {
    return (
        <div className="row ">
            <div className="container-inscripcion py-5 ">
                <div className="row aling-items-center justify-content-center my-3">
                <h6 className="pre-title-ventajas">PASOS A SEGUIR</h6>
                <h2 className="title-ventajas">Completa tu inscripción</h2>
                </div> 
                <div className="container-pasos">
                    <div className="primera-row">
                            <div className="paso">
                            <img className="imagen"  src={imgPaso1} 
                             alt="" />
                             <label  className="decripcion">Realiza tu Pre-inscripcion llenando todos tus datos en  https://isacafullday.com</label>
                            </div>
                            <div className="paso">
                            <img className="imagen"  src={imgPaso2}  alt="" />
                            <label  className="decripcion">Realiza tu pago correspondiente según tu tipo de inscripción a nuestros canales pago(Yape, Plin, BCP e Interbank)</label>
                            </div>
                            <div className="paso">
                            <img className="imagen"  src={imgPaso3}  alt="" />
                            <label  className="decripcion">Validad tu pago llenando el siguiente formulario: <a href="https://forms.gle/h6cAAVkyLoASqLTTA" target="_blanck">Click Aquí!</a>
                           </label>
                            </div>
                    </div>
                    <div className="segunda-row mb-3">
                        <div className="paso">
                            <img className="imagen"  src={imgPaso4}  alt="" />
                            <label  className="decripcion">Espera tu confirmación dentro de las siguientes 24 horas</label>
                        </div>
                        <div className="paso">
                            <img className="imagen"  src={imgPaso5}  alt="" />
                            <label  className="decripcion">Recibiras toda la información del evento, cronograma y detalles del evento.</label>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    );
}