import React from "react";
import "./section-footer.css";
import logoIsaca from "../../assets/img/section-footer/logIsaca_footer.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook,faInstagram } from '@fortawesome/free-brands-svg-icons'



export function SectionFooter() {
    return (
        <div className="row">
            <div className="container-footer py-5">
                <div className="container-logo">
                <img className="img-logo" src={logoIsaca}  alt="" />
                </div>  
                <div className="information">
                    <div className="container-textcontacto">
                        <div className="rct1"></div>
                        <div className="text-contacto">
                            <label className="description">Contacto</label>
                        </div>
                    </div>
                    <div className="container-info">
                        <label className="footer-info" htmlFor="">Telefono: +51 921351292 </label>
                        <label className="footer-info" htmlFor="">Correo: untstudentgroup.isaca@gmail.com</label>
                    </div>
                    <div className="container-redes">
                        <a href="https://www.facebook.com/ISACAUNT">
                        <FontAwesomeIcon icon={faFacebook} className="iconos" style={{color: "#2cca95",}} />
                        </a> 

                        <a href="https://www.instagram.com/isacaunt/" style={{textDecoration:'none'}}>
                        <FontAwesomeIcon icon={faInstagram}  className="iconos" style={{color: "#2cca95",}} />
                        </a>
                    </div>
                  
                </div>              
            </div>
        </div>
    );
}